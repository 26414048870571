<template>
  <b-card>
    <validation-observer
      ref="addTripRules"
      tag="form"
    >
      <b-form>
        <!-- Header: Trip Info -->
        <b-card-header class="d-flex justify-content-between align-items-center pb-1 pt-0 px-0">
          <div>
            <h5 class="mb-0">
              {{ $t('trip.aircraft.informations') }}
            </h5>
          </div>
        </b-card-header>
        <b-row>
          <!-- Field: Aircraft -->
          <b-col
            v-if="organizationAircrafts"
            md="12"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('trip.aircraft.title')"
              rules="required"
              class="validation-required"
            >
              <b-form-group
                :label="$t('trip.aircraft.title')"
                label-for="aircraft"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="organizationAircraftsData"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="organizationAircrafts"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  label="nameInfo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-link :to="{name: 'organization-aircraft-add'}">
              {{ $t('action.add') }}
            </b-link>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>

import { mapState } from 'vuex'
import { fetchOrganizationAircraftsRequest } from '@/request/globalApi/requests/organizationAircraftRequests'

export default {
  components: {

  },
  props: {
    aircraftEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      organizationAircraftsData: null,
      organizationAircrafts: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.APIFetchOrganizationAircrafts()
  },
  methods: {
    APIFetchOrganizationAircrafts() {
      fetchOrganizationAircraftsRequest(this.selectedOrganization.id, { getAllItems: true, active: true })
        .then(response => {
          this.organizationAircrafts = response.data.organizationAircrafts
          this.organizationAircrafts.map(aircraft => {
            let identificator = ''
            if (aircraft.callSign) {
              identificator = aircraft.callSign
            } else {
              identificator = aircraft.registration
            }
            aircraft.nameInfo = `${aircraft.name} - ${identificator}`
          })
        })
    },
    updateAircraft() {
      this.alert(this.$t('alert.edit.success'))
      this.$emit('update:aircraftEdit', false)
    },
    validationForm() {
      return this.$refs.addTripRules.validate().then(success => {
        if (success) {
          if (this.aircraftEdit) {
            this.updateAircraft()
          }
          return true
        }
        return false
      })
    },
  },
}
</script>
