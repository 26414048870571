<template>
  <b-card>
    <validation-observer ref="addTripRules" tag="form" @keydown.enter="preventSubmitOnTripForm($event)">
      <!-- Header: Trip Info -->
      <b-card-header class="d-flex justify-content-between align-items-center pb-1 pt-0 px-0">
        <div>
          <h5 class="mb-0">
            {{ $t('trip.informations') }}
          </h5>
        </div>
      </b-card-header>

      <b-row>
        <!-- Field: Trip name -->
        <b-col cols="12" lg="6" md="6">
          <app-input id="name" v-model="flightData.name" :label="$t('trip.name')" required />
        </b-col>

        <b-col
          v-if="$router.currentRoute.name === 'trip-edit'"
          class="d-flex align-items-end"
          cols="12"
          lg="6"
          md="6"
        >
          <b-form-radio-group
            v-model="flightData.eventType"
            :options="eventTypeOptions"
            :value="flightData.eventType"
            text-field="text"
            value-field="value"
            @input="$emit('onEventType', flightData.eventType)"
          >
            <div class="font-italic font-weight-light font-small-2 mt-50">
              {{ $t('trip.event.note') }}
            </div>
          </b-form-radio-group>
        </b-col>
      </b-row>
    </validation-observer>

    <div v-if="flightEdit" class="d-flex justify-content-end mb-1">
      <b-button class="mr-50" variant="outline-secondary" @click="$emit('update:flight-edit', false)">
        <font-awesome-icon class="mr-50" icon="ban" />
        {{ $t('action.cancel') }}
      </b-button>
      <b-button v-if="$can('TRIP_EDIT')" @click="validationForm">
        <font-awesome-icon class="mr-50" icon="save" />
        {{ $t('action.save') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { editTripInfosRequest } from '@/request/globalApi/requests/tripRequest'

export default {
  name: 'TripEditFlight',

  props: {
    flightEdit: {
      type: Boolean,
      default: false,
    },
    tripData: {
      type: Object,
      default: () => ({
        name: '',
        eventType: 'flight',
      }),
    },
  },
  data() {
    return {
      flightData: {
        name: '',
        eventType: 'flight',
      },
      eventTypeOptions: [
        { text: this.$t('trip.trip_type.flight'), value: 'flight' },
        { text: this.$t('trip.trip_type.transfer'), value: 'transfer' },
      ],
    }
  },
  mounted() {
    this.flightData.name = this._cloneDeep(this.tripData.name)
  },
  methods: {
    APIEditTripInfos() {
      this.tripData.name = this._cloneDeep(this.flightData.name)
      const payload = {
        trip: this.tripData,
        message: this.tripData.name,
      }
      editTripInfosRequest(payload).then(() => {
        this.$emit('update:flight-edit', false)
      })
    },
    validationForm() {
      return this.$refs.addTripRules.validate().then(success => {
        if (success) {
          if (this.flightEdit) {
            this.APIEditTripInfos()
          }
          return true
        }
        return false
      })
    },
    preventSubmitOnTripForm(event) {
      event.preventDefault()
      if (this.flightEdit) {
        this.APIEditTripInfos()
      } else {
        this.$emit('update:flight-add')
      }
    },
  },
}
</script>
